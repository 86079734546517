import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { useIntl } from 'react-intl-next';

import { Anchor } from '@atlaskit/primitives';

import type { FlagType, FlagsStateContainer } from '@confluence/flags';

type IssueCreateTriggerFlagPayload = {
	issueKey: string;
	link: string;
	summary: string;
};

export const useTriggerFlagIssueCreated = (flags: FlagsStateContainer) => {
	const { formatMessage } = useIntl();

	return useCallback(
		(payload: IssueCreateTriggerFlagPayload, flagType: FlagType, message: MessageDescriptor) => {
			void flags.showFlag({
				type: flagType,
				title: formatMessage(message, {
					issueKey: payload?.issueKey,
					issueSummary: payload?.summary,
					link: (chunks: ReactNode[]) => (
						<Anchor href={payload?.link} target="_blank">
							{chunks}
						</Anchor>
					),
				}),
				close: 'auto',
			});
		},
		[flags, formatMessage],
	);
};
