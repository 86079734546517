import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Toggle from '@atlaskit/toggle';
import { Box, Inline, xcss } from '@atlaskit/primitives';

import { messages } from './messages';
import {
	getIsCreateIssueWithAiEnabledByUser,
	setCreateIssueWithAiEnabledByUser,
} from './utils/localStorageHelpers';

const aiToggleContainerStyle = xcss({
	paddingInline: 'space.250',
	paddingBlockStart: 'space.250',
});
const toggleWrapperStyle = xcss({ flexShrink: 0 });

export const IssueCreateWithAiToggle = () => {
	const id = 'issue-create-with-ai-toggle';
	const [isChecked, setIsChecked] = useState(getIsCreateIssueWithAiEnabledByUser());

	useEffect(() => {
		setCreateIssueWithAiEnabledByUser(isChecked);
	}, [isChecked]);

	return (
		<Inline
			xcss={aiToggleContainerStyle}
			alignBlock="start"
			testId="issue-create-side-panel.with-ai"
		>
			<Box xcss={toggleWrapperStyle}>
				<Toggle
					id={id}
					testId="issue-create-side-panel.with-ai.toggle"
					isChecked={isChecked}
					onChange={() => setIsChecked(!isChecked)}
				/>
			</Box>
			<label htmlFor={id}>
				<FormattedMessage {...messages.sidePanelIssueCreateAiToggleLabel} />
			</label>
		</Inline>
	);
};
