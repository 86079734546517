import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Box, xcss, Text, Stack, Inline } from '@atlaskit/primitives';

import { messages } from './messages';

const GeneratingAnimation = (props: { alt: string }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<title>{props.alt}</title>
		<circle cx="5.5" cy="12.5" r="1.5" fill="#0C66E4">
			<animate
				attributeName="cy"
				values="11;13;13;13;13;13;13;11"
				dur="1s"
				repeatCount="indefinite"
				begin="0s"
			/>
		</circle>
		<circle cx="11.5" cy="10.5" r="1.5" fill="#1D7AFC">
			<animate
				attributeName="cy"
				values="11;13;13;13;13;13;13;11"
				dur="1s"
				repeatCount="indefinite"
				begin="0.2s"
			/>
		</circle>
		<circle cx="17.5" cy="12.5" r="1.5" fill="#388BFF">
			<animate
				attributeName="cy"
				values="11;13;13;13;13;13;13;11"
				dur="1s"
				repeatCount="indefinite"
				begin="0.4s"
			/>
		</circle>
	</svg>
);

const loadingContainerStyles = xcss({ height: '100%', padding: 'space.300' });
const loadingIconStyles = xcss({ width: '24px', height: '24px' });

export const IssueCreateAiLoading = () => {
	return (
		<Stack xcss={loadingContainerStyles} alignBlock="stretch">
			<Inline space="space.050" alignBlock="center">
				<Box xcss={loadingIconStyles}>
					<GeneratingAnimation alt="Generating" />
				</Box>
				<Text size="large" color="color.text.subtlest">
					<FormattedMessage {...messages.sidePanelAiLoading} />
				</Text>
			</Inline>
		</Stack>
	);
};
