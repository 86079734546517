import { cfetch } from '@confluence/network';

import type { InsertStorageFragmentRequestPayload } from '../__types__/InsertStorageFragmentRequestPayload';

export const constuctSmartlinkTag = (url: string) => {
	return `<a href="${url}" data-card-appearance="inline">${url}</a>`;
};

export const insertContentAtSelectionEnd = async (
	insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload,
) => {
	try {
		const restUrl = '/wiki/rest/highlighting/1.0/insert-storage-fragment';

		const response = await cfetch(restUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(insertStorageFragmentRequestPayload),
		});

		if (!response.ok) {
			throw new Error('Request failed');
		}

		return await response.json();
	} catch (error) {
		throw error;
	}
};

export const getValidRange = (originalRange: Range | undefined | null): Range | undefined => {
	if (!originalRange) return undefined;

	const newRange = document.createRange();
	let lastTextNode: Text | null = null;
	let lastTextNodeStartOffset: number = 0;
	let lastTextNodeEndOffset: number = 0;

	const INVALID_NODE_SELECTORS = [
		// Status macro
		'span[data-node-type="status"]',
		// Inline Smart Card/Jira issue link
		'[data-inline-card="true"]',
		'.inlineCardView-content-wrap',
		'.smart-link-title-wrapper',
	].join(',');

	const isInvalidNode = (node: Node): boolean => {
		return node.nodeType === Node.ELEMENT_NODE && (node as Element).matches(INVALID_NODE_SELECTORS);
	};

	const hasInvalidAncestor = (node: Node, ancestorLimit: Node): boolean => {
		let currentNode: Node | null = node;
		while (currentNode && currentNode !== ancestorLimit) {
			if (isInvalidNode(currentNode)) {
				return true;
			}
			currentNode = currentNode.parentNode;
		}
		return false;
	};

	const previousNode = (node: Node, range: Range): Node | null => {
		if (node.previousSibling) {
			node = node.previousSibling;
			while (node.hasChildNodes() && !isInvalidNode(node)) {
				node = node.lastChild!;
			}
			return node;
		}
		return node.parentNode && node.parentNode !== range.commonAncestorContainer
			? node.parentNode
			: null;
	};

	let currentNode: Node | null = originalRange.endContainer;
	while (currentNode) {
		// If the current node is a text node and it is not empty, and it does not have an invalid ancestor, then it is a valid node.
		if (
			currentNode.nodeType === Node.TEXT_NODE &&
			currentNode.textContent &&
			currentNode.textContent.trim() !== '' &&
			!hasInvalidAncestor(currentNode, originalRange.commonAncestorContainer)
		) {
			lastTextNode = currentNode as Text;
			if (originalRange.endContainer === currentNode) {
				lastTextNodeEndOffset = originalRange.endOffset;
			} else {
				lastTextNodeEndOffset = currentNode.textContent.length;
			}
			if (originalRange.startContainer === currentNode) {
				lastTextNodeStartOffset = originalRange.startOffset;
			} else {
				lastTextNodeStartOffset = 0;
			}
			break; // Early termination as soon as we find the last valid text node.
		}
		// Terminate when we reach the start of the original range.
		if (currentNode === originalRange.startContainer) break;
		currentNode = previousNode(currentNode, originalRange);
	}

	if (lastTextNode) {
		newRange.setStart(lastTextNode, lastTextNodeStartOffset);
		newRange.setEnd(lastTextNode, lastTextNodeEndOffset);
		return newRange;
	}
	return originalRange;
};

export const getPageContentRange = (): Range | undefined => {
	const contentElement = document.getElementById('main-content');
	if (contentElement) {
		const range = document.createRange();
		range.selectNodeContents(contentElement);
		return range;
	}
	return undefined;
};
