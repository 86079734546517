import React from 'react';

import { IssueCreateWarningDialogContainer } from './IssueCreateWarningDialogContextProvider';
import { IssueCreateSidePanelContainer } from './IssueCreateSidePanelContextProvider';

export const SingleIssueCreateProvidersWrapper: React.FC = ({ children }) => {
	return (
		<IssueCreateSidePanelContainer isGlobal>
			<IssueCreateWarningDialogContainer>{children}</IssueCreateWarningDialogContainer>
		</IssueCreateSidePanelContainer>
	);
};
