import { useMemo } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { type AnalyticsAttributes } from '@atlassian/analytics-bridge';

export const useAiIssueCreateAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const actions = useMemo(() => {
		return {
			fireTrack: (name: string, attributes?: AnalyticsAttributes) => {
				const source = 'aiIssueCreate';
				const [actionSubject, action] = name.split(' ');
				createAnalyticsEvent({
					type: 'sendOperationalEvent',
					data: { source, action, actionSubject, attributes },
				}).fire();
			},
		};
	}, [createAnalyticsEvent]);
	return actions;
};
