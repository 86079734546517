import React from 'react';

import { Box } from '@atlaskit/primitives';

type SidePanelHeaderProps = {
	children: React.ReactNode;
};

export const SidePanelHeader = ({ children }: SidePanelHeaderProps) => {
	return (
		<Box paddingBlock="space.100" paddingInlineStart="space.300" paddingInlineEnd="space.150">
			{children}
		</Box>
	);
};
