import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

// A faster version to count ADF chars, using traverse instead of reduce
export const countADFChars = (value: ADF | undefined) => {
	if (!value) {
		return 0;
	}

	let chars = 0;
	traverse(value, {
		text: (node: ADFEntity) => {
			if (node.text) {
				chars += node.text.length;
			}
		},
		emoji: ({ attrs }) => {
			if (attrs?.text) {
				chars += attrs.text.length;
			}
		},
		paragraph: () => {
			chars += 1;
		},
		mention: ({ attrs }) => {
			if (attrs?.text) {
				chars += attrs.text.length;
			}
		},
	});

	// Subtract 1 due to doc node
	return chars ? chars - 1 : chars;
};
