import { useContext, useEffect, useState } from 'react';

import { RoutesContext } from '@confluence/route-manager';

export const useHasRouteChanged = () => {
	const { getHref } = useContext(RoutesContext);
	const currentHref = getHref();
	const [isRouteChange, setIsRouteChange] = useState(false);
	const [initialURL, setInitialURL] = useState('');

	useEffect(() => {
		setInitialURL(currentHref);
		// Only want to run this effect once on initial render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Initially initialURL is empty, so we need to check if it's empty or not before comparing
		if (!initialURL?.length || !currentHref?.length) {
			return;
		}

		if (initialURL !== currentHref) {
			setIsRouteChange(true);
		}
		// Only want to run this effect when the currentHref changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentHref]);

	return isRouteChange;
};
