import { type DocNode } from '@atlaskit/adf-schema';

import type { GetConfluencePage } from '../../common/types';

export interface ConfluencePageResultInAdf {
	id: string;
	status: string;
	title: string;
	spaceId: string;
	parentId: string;
	parentType: string;
	position: number;
	authorId: string;
	ownerId: string;
	lastOwnerId: string;
	createdAt: string;
	version: {
		createdAt: string;
		message: string;
		number: 19;
		minorEdit: true;
		authorId: string;
	};
	body: {
		atlas_doc_format?: {
			representation: string;
			value: string;
		};
	};
	_links: {
		webui: string;
		editui: string;
		tinyui: string;
	};
}

export const getConfluencePageAdf = async ({
	cloudId,
	contentType,
	pageId,
	signal,
	product = 'confluence',
	experienceId = 'ai-issue-create',
}: GetConfluencePage): Promise<DocNode | undefined> => {
	try {
		const endpointAPI = contentType === 'blogpost' ? `blogposts` : `pages`;
		const response = await fetch(
			`/wiki/api/v2/${endpointAPI}/${pageId}?body-format=atlas_doc_format`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
					'X-Experience-Id': experienceId,
					'X-Product': product,
					'Atl-CloudId': cloudId,
				},
				credentials: 'include',
				signal,
			},
		);

		const data = await response.json();
		if (!data.body?.atlas_doc_format) {
			throw new Error('No atlas doc format found in confluence page');
		}

		return JSON.parse(data.body.atlas_doc_format.value) as DocNode;
	} catch (error: Error | unknown) {
		throw new Error('Request to get confluence page failed');
	}
};
