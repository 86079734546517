import { useCallback, useEffect, useRef, useState } from 'react';

import { type DocNode } from '@atlaskit/adf-schema';

import type { GetConfluencePage } from '../../common/types';
import { useAiIssueCreateAnalytics } from '../ai-issue-create-analytics';

import { getConfluencePageAdf } from './utils';

type UseGetConfluencePage = Omit<GetConfluencePage, 'signal'> & {
	onStateChange: (state: GetConfluencePageState, error?: Error | unknown) => void;
};

type AbortState = { state: 'abort'; confluencePage: undefined };
type LoadingState = { state: 'loading'; confluencePage: undefined };
type SuccessState = { state: 'idle'; confluencePage: DocNode };
type ErrorState = { state: 'error'; error: Error | unknown; confluencePage: undefined };
type GetConfluencePageState = AbortState | LoadingState | ErrorState | SuccessState;

export const useGetConfluencePage = ({
	cloudId,
	contentType,
	experienceId,
	pageId,
	product,
	onStateChange,
}: UseGetConfluencePage): Omit<GetConfluencePageState, 'signal'> & {
	trigger: () => Promise<void>;
} => {
	const { fireTrack } = useAiIssueCreateAnalytics();

	const [fetchState, setGetConfluencePageState] = useState<GetConfluencePageState>({
		state: 'loading',
		confluencePage: undefined,
	});

	const abortControllerRef = useRef<AbortController | null>(null);

	const fetchConfluencePage = useCallback(async () => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		abortControllerRef.current = new AbortController();
		const currentAbortController = abortControllerRef.current;

		setGetConfluencePageState({ state: 'loading', confluencePage: undefined });

		try {
			fireTrack('aiIssueCreateGetConfluencePageRequest start');
			const confluencePage = await getConfluencePageAdf({
				cloudId,
				contentType,
				experienceId,
				pageId,
				product,
				signal: currentAbortController.signal,
			});
			fireTrack('aiIssueCreateGetConfluencePageRequest complete', {
				haveResult: !!confluencePage,
			});

			if (currentAbortController.signal.aborted) {
				setGetConfluencePageState({ state: 'abort', confluencePage: undefined });
				return;
			}

			if (confluencePage) {
				setGetConfluencePageState({ state: 'idle', confluencePage });
			} else {
				setGetConfluencePageState({
					state: 'error',
					error: new Error('Error on getting confluence page as ADF'),
					confluencePage: undefined,
				});
			}
		} catch (e: Error | unknown) {
			fireTrack('aiIssueCreateGetConfluencePageRequest fail', { message: (e as Error).message });
			if (currentAbortController.signal.aborted) {
				setGetConfluencePageState({ state: 'abort', confluencePage: undefined });
				return;
			}

			setGetConfluencePageState({ state: 'error', error: e, confluencePage: undefined });
		}
	}, [cloudId, contentType, experienceId, pageId, product, setGetConfluencePageState, fireTrack]);

	useEffect(() => {
		fetchConfluencePage();

		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [fetchConfluencePage]);

	useEffect(() => {
		if (onStateChange) {
			onStateChange(fetchState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchState.state, fetchState.confluencePage, onStateChange]);

	return { ...fetchState, trigger: fetchConfluencePage };
};
