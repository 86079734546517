import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { di } from 'react-magnetic-di';

import { doc, p } from '@atlaskit/adf-utils/builders';
import { cssMap } from '@atlaskit/css';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box } from '@atlaskit/primitives/compiled';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { adfToMarkdownPlus, markdownPlusToAdf } from '@atlassian/adf-markdown-transformer';
import { AIEventsInstrumentationProvider } from '@atlassian/ai-analytics';
import { GicAnywhere } from '@atlassian/gic-anywhere';

import {
	type AiIssueCreateState,
	type ConfluenceAiIssueCreateProps,
	type StateSource,
} from '../../common/types';
import { useAiIssueCreateAnalytics } from '../../services/ai-issue-create-analytics';
import { useFetchAiSuggestedFields } from '../../services/fetch-ai-suggested-fields';
import { useGetConfluencePage } from '../../services/get-confluence-page';

import { countADFChars } from './utils';

const DEFAULT_TIMEOUT = 7 * 1000; // 7s timeout, typical GIC load is ~3s

const style = cssMap({
	aiIssueCreateWrapper: { flexGrow: '1' },
});

type ConfluenceAiIssueCreateState = {
	state: AiIssueCreateState;
	error?: Error | unknown;
	[key: string]: any;
};

export const ConfluenceAiIssueCreateComponent = ({
	cloudId,
	overrideCloudIdForSitePicker,
	contentType,
	pageId,
	highlightedTextAdf = doc(p('')),
	timeoutMs = DEFAULT_TIMEOUT,
	onInit,
	onSuccess,
	onFailure,
	onClose,
	onTimeout,
	testId,
	onStateChange,
	consumer,
	setAiAnalyticsContext,
}: ConfluenceAiIssueCreateProps) => {
	di(useFetchAiSuggestedFields, GicAnywhere);

	const { fireTrack } = useAiIssueCreateAnalytics();
	const [confluencePage, setConfluencePage] = useState('');
	const [idMap, setIdMap] = useState({});
	const [isIdMapReady, setIsIdMapReady] = useState(false);

	// Custom handler to wrap multiple onStateChange calls
	const handleStateChange = useCallback(
		(source: StateSource, state: ConfluenceAiIssueCreateState) => {
			// trigger error if any state is errored
			if (onFailure && state?.state === 'error') {
				onFailure(state.error);
				fireTrack(`${source} failed`);
			}
			if (onStateChange) {
				onStateChange(state.state, source);
				fireTrack(`${source} updated`, { state: state.state });
			}
		},
		[onFailure, onStateChange, fireTrack],
	);

	const handleConfluencePageStateChange = useCallback(
		(newState: ConfluenceAiIssueCreateState) =>
			handleStateChange('aiIssueCreateGetConfluencePageState', newState),
		[handleStateChange],
	);
	const handleAiSuggestedFieldsStateChange = useCallback(
		(newState: ConfluenceAiIssueCreateState) =>
			handleStateChange('aiIssueCreateFetchSuggestedFieldsState', newState),
		[handleStateChange],
	);

	// Fetch confluence page
	const { state: confluencePageState, confluencePage: confluencePageAsAdf } = useGetConfluencePage({
		cloudId,
		contentType,
		experienceId: 'ai-issue-create-confluence-single',
		pageId,
		onStateChange: handleConfluencePageStateChange,
	});

	// Effect to transform confluence page ADF to markdown after fetching
	useEffect(() => {
		if (confluencePageState === 'idle' && confluencePageAsAdf) {
			const { markdown, idMap: newIdMap } = adfToMarkdownPlus(confluencePageAsAdf);
			setAiAnalyticsContext &&
				setAiAnalyticsContext('pageContextLength', countADFChars(confluencePageAsAdf));
			setConfluencePage(markdown);
			setIdMap(newIdMap);
			setIsIdMapReady(true); // Set idMap ready when it's set
		}
	}, [confluencePageState, confluencePageAsAdf, setAiAnalyticsContext]);

	const highlightedText = useMemo(() => {
		// Convert highlighted text to markdown only when idMap from full page is ready
		if (isIdMapReady) {
			return adfToMarkdownPlus(highlightedTextAdf, undefined, idMap).markdown;
		}

		return '';
	}, [highlightedTextAdf, idMap, isIdMapReady]);

	// Fetch AI suggested fields
	const { state: suggestedFieldsState, fields } = useFetchAiSuggestedFields({
		cloudId,
		confluencePage,
		experienceId: 'ai-issue-create-confluence-single',
		highlightedText,
		product: 'confluence',
		onStateChange: handleAiSuggestedFieldsStateChange,
	});

	const isLoading = confluencePageState === 'loading' || suggestedFieldsState === 'loading';
	const isErrored = confluencePageState === 'error' || suggestedFieldsState === 'error';
	const isPayloadReady = confluencePageState === 'idle' && suggestedFieldsState === 'idle';

	const descriptionADF = useMemo(() => {
		if (isLoading || isErrored) {
			return doc(p(''));
		}

		return fields?.description
			? markdownPlusToAdf({ markdown: fields.description, idMap })
			: doc(p(''));
	}, [isLoading, isErrored, fields, idMap]);

	useEffect(() => {
		setAiAnalyticsContext &&
			setAiAnalyticsContext('aiResponseLength', countADFChars(descriptionADF));
	}, [descriptionADF, setAiAnalyticsContext]);

	useEffect(() => {
		setAiAnalyticsContext &&
			setAiAnalyticsContext('highlightedTextLength', countADFChars(highlightedTextAdf));
	}, [highlightedTextAdf, setAiAnalyticsContext]);

	return (
		<Box xcss={style.aiIssueCreateWrapper} testId={testId ?? 'default-confluence-ai-issue-create'}>
			{fg('performance_metrics_for_gic_anywhere') && (
				<>
					<UFOLoadHold
						name="ai-issue-create.adf-to-markdown-plus"
						hold={confluencePageState !== 'loading' && !isIdMapReady}
					/>
					<UFOLoadHold
						name="ai-issue-create.confluence-page-loading"
						hold={confluencePageState === 'loading'}
					/>
					<UFOLoadHold
						name="ai-issue-create.suggested-fields-loading"
						hold={suggestedFieldsState === 'loading'}
					/>
				</>
			)}
			{!isErrored && (
				<GicAnywhere
					cloudId={cloudId}
					summary={fields?.summary || ''}
					descriptionAdf={descriptionADF}
					timeoutMs={timeoutMs}
					onInit={onInit}
					onSuccess={onSuccess}
					onFailure={onFailure}
					onClose={onClose}
					onTimeout={onTimeout}
					consumer={consumer}
					isPayloadReady={isPayloadReady}
					overrideCloudId={overrideCloudIdForSitePicker}
				/>
			)}
		</Box>
	);
};

export const ConfluenceAiIssueCreate = ({
	cloudId,
	contentType,
	pageId,
	overrideCloudIdForSitePicker,
	highlightedTextAdf = doc(p('')),
	timeoutMs = DEFAULT_TIMEOUT,
	onInit,
	onSuccess,
	onFailure,
	onClose,
	onTimeout,
	testId,
	onStateChange,
	consumer = 'confluenceHighlight',
	setAiAnalyticsContext,
}: ConfluenceAiIssueCreateProps) => {
	return (
		<AIEventsInstrumentationProvider
			config={{
				// Required configruation
				product: 'confluence',
				subproduct: 'jira',
				aiFeatureName: 'confluenceAiWorkCreation',
				proactiveGeneratedAI: 0,
				userGeneratedAI: 1,
				// Optional configuration
				source: 'confluenceHighlightIssueCreate',
				skipAISessionValidation: true,
			}}
		>
			<ConfluenceAiIssueCreateComponent
				cloudId={cloudId}
				contentType={contentType}
				pageId={pageId}
				highlightedTextAdf={highlightedTextAdf}
				timeoutMs={timeoutMs}
				onInit={onInit}
				onSuccess={onSuccess}
				onFailure={onFailure}
				onClose={onClose}
				onTimeout={onTimeout}
				testId={testId}
				onStateChange={onStateChange}
				consumer={consumer}
				overrideCloudIdForSitePicker={overrideCloudIdForSitePicker}
				setAiAnalyticsContext={setAiAnalyticsContext}
			/>
		</AIEventsInstrumentationProvider>
	);
};
